import { H3 } from '../../Atoms/Typography/Headings/Heading';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';
import FeatureBlockModel from '../../Models/Blocks/FeatureBlock/FeatureBlockModel.interface';
import Button from '../../Atoms/Buttons/Button';
import { CSS } from '@stitches/react';
import { styled } from '../../stitches.config';
type LayoutProps = {
  big: boolean;
};

export function FeatureBlockComponent({
  preHeader,
  header,
  body,
  links,
  image,
  big,
}: LayoutProps & FeatureBlockModel) {
  const navigate = useKexNavigate();

  if (big) {
    return (
      <BigContainer>
        <BigTag>{preHeader}</BigTag>
        <H3 noMargin={true} css={bigHeader}>
          {header}
        </H3>
        <StyledParagraph>{body}</StyledParagraph>
        <ButtonContainer>
          {links.map((link) => (
            <Button
              key={link.href}
              onClick={() => link.href && navigate(link.href)}
              type="outlined"
            >
              {link.text}
            </Button>
          ))}
        </ButtonContainer>
        <StyledImage
          src={image.src}
          alt={image.alt ?? ''}
          title={image.title}
        />
      </BigContainer>
    );
  } else {
    const href = links.filter((l) => !!l.href)[0]?.href;

    return (
      <SmallContainer href={href}>
        <div>
          <SmallTag>{preHeader}</SmallTag>
          <H3 css={smallHeader}>{header}</H3>
        </div>
        <StyledImageSmall
          src={image.src}
          alt={image.alt ?? ''}
          title={image.title}
        />
      </SmallContainer>
    );
  }
}

const bigHeader: CSS = {
  margin: '0px',
  pr: 8,
  pl: 8,
  fontSize: '24px',
  '@mediaMaxLarge': {
    pl: 4,
    pr: 4,
  },
};

const smallHeader: CSS = {
  margin: '0px',
  pr: 8,
  pl: 8,
  fontSize: '24px',
  pb: 8,
  '@mediaMaxLarge': {
    pl: 4,
    pr: 4,
    pb: 4,
  },
};

const container = {
  background: '$primary4',
  placeSelf: 'stretch stretch',
  display: 'grid',
  gridTemplateRows: 'min-content auto',
  gridAutoFlow: 'column',
  color: '$secondary1',
};

const SmallContainer = styled('a', {
  ...container,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '$primary3',
    '& img': {
      opacity: '0.9',
    },
  },
  '&:focus': {
    outlineOffset: 8,
  },
});

const BigContainer = styled('div', {
  ...container,
  gridRow: '1 / 3',
  display: 'grid',
  gridTemplateRows: 'min-content min-content 1fr min-content',
  gridAutoFlow: 'column',
  '@mediaMaxLarge': {
    gridTemplateColumns: '100%',
    gridAutoFlow: 'row',
    gridTemplateRows: '140px min-content 1fr min-content',
  },
});

const StyledImage = styled('img', {
  flexGrow: 0,
  placeSelf: 'stretch stretch',
  gridRow: '1 / 5',
  objectFit: 'cover',
  '@mediaMaxLarge': {
    gridRow: '1',
    placeSelf: 'stretch',
  },
});

const StyledImageSmall = styled('img', {
  transition: 'all 0.2s ease-in-out',
  flexGrow: 0,
  placeSelf: 'stretch end',
  gridRow: '1 / 3',
  objectFit: 'cover',
});

const StyledParagraph = styled('p', {
  textOverflow: 'ellipsis',
  pt: 2,
  lh: '$lh155',
  pr: 8,
  pl: 8,
  '@mediaMaxLarge': {
    pt: 2,
    px: 4,
  },
});

const ButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifySelf: 'end',
  justifyContent: 'flex-start',
  width: '100%',
  py: 8,
  px: 4,
  '@mediaMinLarge': {
    flexDirection: 'row',
    px: 8,
  },
});

const SmallTag = styled('p', {
  fs: 6,
  color: '$primaryLight3',
  pb: 2,
  pt: 8,
  pr: 8,
  pl: 8,
  textTransform: 'uppercase',
  '@mediaMaxLarge': {
    pt: 4,
    px: 4,
  },
});

const BigTag = styled('p', {
  fs: 6,
  color: '$primaryLight3',
  pb: 2,
  pt: 8,
  px: 8,
  textTransform: 'uppercase',
  '@mediaMaxLarge': {
    px: 4,
    pt: 4,
  },
});
