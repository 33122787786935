import GridItem from 'Atoms/Grids/GridItem';
import FeatureBlockModel from '../../Models/Blocks/FeatureBlock/FeatureBlockModel.interface';
import { styled } from '../../stitches.config';
import { FeatureBlockComponent } from './FeatureBlock';

type FeatureBlock = {
  blockType: 'FeatureBlock';
  content: FeatureBlockModel;
};

type LayoutKey = '2+1' | '1+2';

type Model = {
  inEditMode: boolean;
  layout: string;
  blocks: FeatureBlock[];
};

type PropType = {
  content: Model;
};

const checkNever = (_: never) => {};

const toLayoutKey = (key: string): LayoutKey => {
  const typedKey = key as LayoutKey;

  if (typedKey === '2+1') return '2+1';
  if (typedKey === '1+2') return '1+2';

  // if we add an option to LayoutKey, this line will complain
  checkNever(typedKey);

  console.error(`Unknown layout key ${typedKey}, defaulting to 1+2`);
  return '1+2';
};

function FeatureArrangementBlock({ content: { layout, blocks } }: PropType) {
  if (blocks.length !== 3) {
    throw Error('Feature arrangement currently supports only 3 block');
  }

  const layoutKey = toLayoutKey(layout);

  const [first, second, third] = blocks;

  return (
    <GridItem>
      <FeatureArrangementRoot>
        <FeatureBlockComponent {...first.content} big={layoutKey === '1+2'} />
        <FeatureBlockComponent {...second.content} big={false} />
        <FeatureBlockComponent {...third.content} big={layoutKey === '2+1'} />
      </FeatureArrangementRoot>
    </GridItem>
  );
}

const FeatureArrangementRoot = styled('div', {
  pxy: 0,
  display: 'grid',
  gridTemplateRows: 'min-content min-content',
  gridTemplateColumns: '2.1fr 1fr',
  gridGap: '32px',
  gridAutoFlow: 'column',
  mb: 16,
  '@mediaMaxLarge': {
    display: 'flex',
    flexDirection: 'column',
    ml: 0,
    mr: 0,
    gridGap: '24px',
    mb: 12,
  },
});

export default FeatureArrangementBlock;
